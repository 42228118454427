import React, {
  useState,
  KeyboardEvent,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { Send, Video, Mic, Clock, PenLine } from "lucide-react";
import { Conversation } from "./types";
import { FaSpinner } from "react-icons/fa";

interface InputAreaProps {
  onSendMessage: (message: string, conversationId: string) => Promise<void>;
  onStartNewConversation: () => Promise<Conversation | null>;
  isLoading: boolean;
  currentConversationId: string | null;
  isInputDisabled: boolean;
}

const InputArea: React.FC<InputAreaProps> = ({
  onSendMessage,
  onStartNewConversation,
  isLoading,
  currentConversationId,
  isInputDisabled,
}) => {
  const [input, setInput] = useState("");
  const [isSending, setIsSending] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [hoveringButton, setHoveringButton] = useState<string | null>(null);
  const [sendTimeout, setSendTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isPendingSend, setIsPendingSend] = useState(false);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "28px";
      const scrollHeight = textarea.scrollHeight;
      textarea.style.height = scrollHeight > 28 ? `${scrollHeight}px` : "28px";
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  const cancelPendingSend = useCallback(() => {
    if (sendTimeout) {
      clearTimeout(sendTimeout);
      setSendTimeout(null);
      setIsPendingSend(false);
      setIsSending(false);
    }
  }, [sendTimeout]);

  const handleSend = useCallback(async () => {
    if (input.trim() && !isLoading && !isSending) {
      setIsPendingSend(true);
      const messageToSend = input.trim();

      const timeout = setTimeout(async () => {
        try {
          setInput("");
          setIsPendingSend(false);
          setIsSending(true);
          await onSendMessage(messageToSend, currentConversationId || "");
        } catch (error) {
          console.error("Error sending message:", error);
        } finally {
          setIsSending(false);
          setSendTimeout(null);
        }
      }, 0);

      setSendTimeout(timeout);
    }
  }, [input, isLoading, isSending, currentConversationId, onSendMessage]);

  const handleStartNewConversation = async () => {
    try {
      setIsSending(true);
      const newConversation = await onStartNewConversation();
      if (newConversation) {
        console.log("New conversation created:", newConversation);
      } else {
        console.error("Failed to create a new conversation");
      }
    } catch (error) {
      console.error("Error creating new conversation:", error);
    } finally {
      setIsSending(false);
    }
  };

  const tooltipStyle =
    "absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-green-50 text-green-800 text-xs py-1 px-3 rounded-full z-10 whitespace-nowrap border border-green-200 shadow-sm";

  const showAdditionalIcons = input.trim() === "";

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    cancelPendingSend();
    setInput(e.target.value);
    adjustTextareaHeight();
  };

  useEffect(() => {
    return () => {
      cancelPendingSend();
    };
  }, [cancelPendingSend]);

  return (
    <div className="bg-gradient-to-b from-green-50 to-green-100 p-4 shadow-lg">
      <div className="max-w-2xl mx-auto">
        <div className="relative flex items-center bg-white rounded-2xl border border-green-200 hover:border-green-300 transition-all duration-300 shadow-sm py-1 pr-2">
          <textarea
            ref={textareaRef}
            value={input}
            onChange={handleInputChange}
            onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
            disabled={isInputDisabled}
            className="flex-grow px-3 text-gray-800 placeholder-gray-500 
              bg-transparent focus:outline-none focus:ring-0
              text-sm leading-6 placeholder:italic placeholder:font-light
              resize-none overflow-hidden"
            style={{ height: "28px", maxHeight: "150px" }}
          />
          <button
            onClick={handleSend}
            disabled={isLoading || !input.trim() || isSending || isPendingSend}
            className={`rounded-full p-0.5 ${
              isLoading || isSending
                ? "bg-green-300"
                : isPendingSend
                ? "bg-yellow-500"
                : "bg-green-500 hover:bg-green-600"
            } transition-all duration-300 text-white h-7 w-7 flex items-center justify-center`}
          >
            {isLoading || isSending ? (
              <FaSpinner className="animate-spin" size={16} />
            ) : isPendingSend ? (
              <Clock className="animate-pulse" size={16} />
            ) : (
              <Send size={16} />
            )}
          </button>
          {showAdditionalIcons && (
            <>
              <div
                className="relative group ml-2"
                onMouseEnter={() => setHoveringButton("mic")}
                onMouseLeave={() => setHoveringButton(null)}
              >
                <div className="rounded-full p-0.5 bg-gray-100 cursor-not-allowed h-7 w-7 flex items-center justify-center">
                  <Mic size={16} color="#4A5568" />
                </div>
                {hoveringButton === "mic" && (
                  <span className={tooltipStyle}>Coming Soon</span>
                )}
              </div>
              <div
                className="relative group ml-2"
                onMouseEnter={() => setHoveringButton("video")}
                onMouseLeave={() => setHoveringButton(null)}
              >
                <div className="rounded-full p-0.5 bg-gray-100 cursor-not-allowed h-7 w-7 flex items-center justify-center">
                  <Video size={16} color="#4A5568" />
                </div>
                {hoveringButton === "video" && (
                  <span className={tooltipStyle}>Coming Soon</span>
                )}
              </div>
              <button
                onClick={handleStartNewConversation}
                disabled={isLoading}
                className="relative group ml-2"
                onMouseEnter={() => setHoveringButton("newConversation")}
                onMouseLeave={() => setHoveringButton(null)}
              >
                <div className="rounded-full p-0.5 bg-blue-100 hover:bg-blue-200 transition-all duration-300 h-7 w-7 flex items-center justify-center">
                  <PenLine size={16} className="text-blue-700" />
                </div>
                {hoveringButton === "newConversation" && (
                  <span className={tooltipStyle}>New Chat</span>
                )}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InputArea;
