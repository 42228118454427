import React, { useEffect, useRef, useState, useCallback } from "react";
import InputArea from "./InputArea";
import { Conversation, Message } from "./types";
import { useApi } from "./apiUtils";
import backgroundImage from "../assets/chat_background.jpg";
import ChatMessages from "./ChatMessages";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-hot-toast";

interface ChatContainerProps {
  onNewConversation: () => Promise<Conversation | null>;
  onUpdateConversation: (updatedConversation: Conversation) => Promise<void>;
  onSendMessage: (message: string, conversationId: string) => Promise<void>;
  onSendAudio: (
    audioBlob: Blob,
    conversationId: string
  ) => Promise<{
    response: string;
    audio_response?: string;
    transcription: string;
  }>;
  currentConversation: Conversation | null;
  currentConversationId: string | null;
  isLoading: boolean;
  // Add this prop
  setIsLoading: (isLoading: boolean) => void;
  isInitialMessageReceived: boolean;
  setIsInitialMessageReceived: (value: boolean) => void;
}

const ChatContainer: React.FC<ChatContainerProps> = ({
  onNewConversation,
  onSendMessage,
  currentConversation,
  currentConversationId,
  isLoading: isAppLoading,
  onSendAudio,
  setIsLoading,
  isInitialMessageReceived,
  setIsInitialMessageReceived,
}) => {
  const { getConversationMessages, getConfig } = useApi();
  const [messages, setMessages] = useState<Message[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [isWaitingForWelcome, setIsWaitingForWelcome] = useState(false);
  const [currentController, setCurrentController] =
    useState<AbortController | null>(null);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const { saveConfig } = useApi();
  const [isConfigChecked, setIsConfigChecked] = useState(false);

  useEffect(() => {
    if (currentConversation && currentConversation.messages) {
      setMessages(currentConversation.messages);
      setIsMessagesLoading(false);
      setIsWaitingForWelcome(false);
      setIsInitialMessageReceived(currentConversation.messages.length > 0);
    } else if (currentConversationId) {
      setIsMessagesLoading(true);
      setIsWaitingForWelcome(true);
      getConversationMessages(currentConversationId)
        .then((fetchedMessages: Message[]) => {
          setMessages(fetchedMessages);
          setIsMessagesLoading(false);
          setIsWaitingForWelcome(fetchedMessages.length === 0);
          setIsLoading(false);
          setIsInitialMessageReceived(fetchedMessages.length > 0);
        })
        .catch((error) => {
          console.error("Error fetching messages:", error);
          setIsMessagesLoading(false);
          setIsWaitingForWelcome(false);
          setIsLoading(false);
          setIsInitialMessageReceived(false);
        });
    } else {
      setMessages([]);
      setIsWaitingForWelcome(false);
      setIsLoading(false);
      setIsInitialMessageReceived(false);
    }
  }, [
    currentConversation,
    currentConversationId,
    getConversationMessages,
    setIsLoading,
    setIsInitialMessageReceived,
  ]);

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100); // Small delay to ensure content is rendered
    }
  }, []);

  useEffect(() => {
    const container = document.querySelector(".overflow-y-auto");
    if (container) {
      container.scrollTop = 0; // Reset scroll position when messages change
    }
  }, [currentConversationId]);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages, scrollToBottom]);

  useEffect(() => {
    if (currentConversationId) {
      setIsMessagesLoading(true);
      getConversationMessages(currentConversationId)
        .then((fetchedMessages: Message[]) => {
          setMessages(fetchedMessages);
          setIsMessagesLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching messages:", error);
          setIsMessagesLoading(false);
        });
    } else {
      // Clear messages when there's no current conversation
      setMessages([]);
    }
  }, [currentConversationId, getConversationMessages]);

  const handleSendMessage = async (message: string, conversationId: string) => {
    if (currentController) {
      currentController.abort();
    }

    const controller = new AbortController();

    try {
      setCurrentController(controller);

      // Add user message immediately
      const userMessage: Message = {
        content: message,
        created_at: new Date().toISOString(),
        is_user: true,
        conversation_id: conversationId,
      };

      setMessages((prevMessages) => [...prevMessages, userMessage]);

      // Send message and wait for response
      await onSendMessage(message, conversationId);

      // Fetch updated messages including the AI response
      const updatedMessages = await getConversationMessages(conversationId);
      setMessages(updatedMessages);
    } catch (error: unknown) {
      if (error instanceof Error && error.name === "AbortError") {
        console.log("Message sending was cancelled");
        return;
      }
      console.error("Error sending message:", error);
    } finally {
      setCurrentController(null);
    }
  };

  useEffect(() => {
    async function checkMicrophoneAccess() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        console.log("Microphone access granted", stream);
        const track = stream.getAudioTracks()[0];
        console.log("Audio track settings:", track.getSettings());
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    }

    checkMicrophoneAccess();
  }, []);

  useEffect(() => {
    const checkConfig = async () => {
      try {
        const config = await getConfig();
        console.log("Config:", config);
        // Show language modal if language is null, undefined, or empty string
        if (
          !config ||
          config.language === null ||
          config.language === undefined ||
          config.language === ""
        ) {
          setShowLanguageModal(true);
        }
      } catch (error) {
        console.error("Error checking config:", error);
        // Show language modal on error as well
        setShowLanguageModal(true);
      } finally {
        setIsConfigChecked(true);
      }
    };

    checkConfig();
  }, [getConfig]);

  useEffect(() => {
    // Only show language modal if config is checked and there's no current conversation
    if (
      isConfigChecked &&
      !currentConversation &&
      !currentConversationId &&
      !isAppLoading
    ) {
      setShowLanguageModal(true);
    }
  }, [
    currentConversation,
    currentConversationId,
    isAppLoading,
    isConfigChecked,
  ]);

  const handleLanguageSelect = async (language: string) => {
    setShowLanguageModal(false);
    setIsLoading(true);

    const selectedLanguage = language === "English" ? "English" : "French";

    try {
      await saveConfig("text-to-text", "", selectedLanguage, "female");

      // Show toast message immediately after saving config
      const message =
        selectedLanguage === "English"
          ? "Great, thank you! If you need to change the language again, click on the Settings icon at the top right of the screen."
          : "Parfait, merci ! Si vous souhaitez changer de langue à nouveau, cliquez sur l'icône Réglages en haut à droite de l'écran.";

      toast.success(message, {
        position: "top-center",
        duration: 5000,
        id: "language-selection-toast", // Add unique ID
      });

      // Create a new conversation after showing toast
      const newConversation = await onNewConversation();
      if (!newConversation) {
        throw new Error("Failed to create new conversation");
      }
    } catch (error) {
      console.error("Error during language selection:", error);
      toast.error("An error occurred while setting up the conversation", {
        position: "top-center",
        id: "language-error-toast", // Add unique ID
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="flex flex-col h-full relative"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {showLanguageModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl">
            <h3 className="text-xl font-semibold mb-4 text-center text-gray-800">
              Choose Your Language
            </h3>
            <div className="flex justify-center gap-3">
              <button
                onClick={() => handleLanguageSelect("English")}
                className="flex items-center gap-3 p-4 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <div className="w-8 h-8 flex items-center justify-center">
                  <img
                    src="https://flagcdn.com/w160/us.png"
                    srcSet="https://flagcdn.com/w320/us.png 2x"
                    alt="USA flag"
                    className="h-[1.6rem] w-auto rounded-sm shadow-sm"
                    loading="eager"
                  />
                </div>
                <span className="text-gray-700 text-lg">English</span>
              </button>
              <button
                onClick={() => handleLanguageSelect("Français")}
                className="flex items-center gap-3 p-4 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <div className="w-8 h-8 flex items-center justify-center">
                  <img
                    src="https://flagcdn.com/w160/fr.png"
                    srcSet="https://flagcdn.com/w320/fr.png 2x"
                    alt="French flag"
                    className="h-[1.6rem] w-auto rounded-sm shadow-sm"
                    loading="eager"
                  />
                </div>
                <span className="text-gray-700 text-lg">Français</span>
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col h-full z-10">
        <div className="flex-1 overflow-y-auto overscroll-contain pt-6 sm:pt-8">
          <div className="max-w-3xl w-full mx-auto pb-6 sm:pb-8">
            {isAppLoading || isMessagesLoading || isWaitingForWelcome ? (
              <div className="flex items-center justify-center h-64">
                <FaSpinner className="animate-spin text-4xl text-green-500" />
              </div>
            ) : messages.length > 0 ? (
              <div className="w-full">
                <ChatMessages messages={messages} />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-64 text-center p-4">
                <svg
                  className="w-20 h-20 sm:w-28 sm:h-28 text-green-500/80 mb-6"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                  />
                </svg>
                <h3 className="text-xl sm:text-2xl font-semibold text-green-600/90 mb-3">
                  Welcome! I am ioda, your AI Wellness Coach
                </h3>
                <p className="text-sm sm:text-base text-gray-600 max-w-md leading-relaxed">
                  I'm here to provide a safe space where you can share your
                  worries, explore your feelings, and find practical ways to
                  manage stress. Let's work together towards your emotional
                  well-being.
                </p>
              </div>
            )}
            <div ref={messagesEndRef} className="h-px" />
          </div>
        </div>
        <InputArea
          onSendMessage={handleSendMessage}
          onStartNewConversation={onNewConversation}
          isLoading={isAppLoading || isMessagesLoading || isWaitingForWelcome}
          currentConversationId={currentConversation?.id || null}
          isInputDisabled={!isInitialMessageReceived}
        />
      </div>
    </div>
  );
};

export default ChatContainer;
