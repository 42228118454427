import React, { createContext, useContext, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FeedbackOutlined } from "@mui/icons-material";
import { Toaster, toast } from "react-hot-toast";
import { useApi } from "./apiUtils";

interface ChatMessagesProps {
  messages: Message[];
}

const ListContext = createContext<"ul" | "ol">("ul");

interface FeedbackData {
  messageContent: string;
  conversationId: string;
  messageId: string;
  feedback: string;
}

interface Message {
  content: string;
  created_at: string;
  is_user: boolean;
  conversation_id: string;
  id?: string;
}

const ChatMessages: React.FC<ChatMessagesProps> = ({ messages }) => {
  const { submitFeedback } = useApi();
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const [feedbackText, setFeedbackText] = useState("");

  const handleFeedbackSubmit = async () => {
    if (!selectedMessage) return;

    try {
      const feedbackData: FeedbackData = {
        messageContent: selectedMessage.content,
        conversationId: selectedMessage.conversation_id,
        messageId: selectedMessage.id || "",
        feedback: feedbackText,
      };

      await submitFeedback(feedbackData);
      toast.success("Thank you for your feedback!", {
        duration: 3000,
        position: "bottom-center",
        style: {
          background: "#4ade80",
          color: "#fff",
          padding: "12px 24px",
          borderRadius: "8px",
        },
        icon: "🙏",
      });
      setFeedbackModalOpen(false);
      setFeedbackText("");
      setSelectedMessage(null);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Failed to submit feedback. Please try again.", {
        duration: 3000,
        position: "bottom-center",
        style: {
          background: "#ef4444",
          color: "#fff",
          padding: "12px 24px",
          borderRadius: "8px",
        },
      });
    }
  };

  const ListItem: React.FC<React.LiHTMLAttributes<HTMLLIElement>> = ({
    children,
    ...props
  }) => {
    const listType = useContext(ListContext);
    const isOrdered = listType === "ol";
    return (
      <li
        className={`mb-1 ${isOrdered ? "list-decimal" : "list-disc"}`}
        {...props}
      >
        {children}
      </li>
    );
  };

  return (
    <div className="space-y-6 px-4 sm:px-6 md:px-8 min-h-0">
      <Toaster
        containerStyle={{
          position: "fixed",
          zIndex: 9999,
          top: 20,
        }}
      />
      {messages.map((message, index) => (
        <div
          key={index}
          className={`mb-4 flex ${
            message.is_user ? "justify-end" : "justify-start"
          }`}
        >
          <div className="relative group max-w-[85%] sm:max-w-[75%]">
            <div
              className={`inline-block w-full p-3 pb-1 rounded-2xl font-light text-sm ${
                message.is_user
                  ? "bg-blue-100 text-blue-800"
                  : "bg-green-100 text-green-800"
              }`}
              style={{
                boxShadow: message.is_user
                  ? "4px 4px 15px rgba(66, 153, 225, 0.4), -2px -2px 15px rgba(255, 255, 255, 0.8), inset 2px 2px 5px rgba(255, 255, 255, 0.5), inset -2px -2px 5px rgba(66, 153, 225, 0.2)"
                  : "-4px 4px 15px rgba(72, 187, 120, 0.4), 2px -2px 15px rgba(255, 255, 255, 0.8), inset 2px 2px 5px rgba(255, 255, 255, 0.5), inset -2px -2px 5px rgba(72, 187, 120, 0.2)",
                backgroundImage:
                  "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23f0f0f0' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E\")",
                backgroundBlendMode: "soft-light",
                border: message.is_user
                  ? "1px solid rgba(66, 153, 225, 0.3)"
                  : "1px solid rgba(72, 187, 120, 0.3)",
              }}
            >
              <div className="prose prose-sm max-w-none">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    code({ node, inline, className, children, ...props }: any) {
                      const match = /language-(\w+)/.exec(className || "");
                      return !inline && match ? (
                        <SyntaxHighlighter
                          style={atomDark as any}
                          language={match[1]}
                          PreTag="div"
                          {...props}
                        >
                          {String(children).replace(/\n$/, "")}
                        </SyntaxHighlighter>
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      );
                    },
                    ul: ({ children }) => (
                      <ListContext.Provider value="ul">
                        <ul className="list-disc pl-5 mb-2">{children}</ul>
                      </ListContext.Provider>
                    ),
                    ol: ({ children }) => (
                      <ListContext.Provider value="ol">
                        <ol className="list-decimal pl-5 mb-2">{children}</ol>
                      </ListContext.Provider>
                    ),
                    li: ({ children, ...props }) => (
                      <ListItem {...props}>{children}</ListItem>
                    ),
                    p: ({ children }) => <p className="mb-2">{children}</p>,
                  }}
                >
                  {message.content}
                </ReactMarkdown>
              </div>

              {!message.is_user && (
                <div className="flex items-center justify-start gap-2 mt-2 pt-1 border-t border-green-200/50">
                  <button
                    onClick={() => {
                      setSelectedMessage(message);
                      setFeedbackModalOpen(true);
                    }}
                    className="p-1.5 rounded-full hover:bg-green-200/50 transition-colors"
                    title="Report an issue"
                  >
                    <FeedbackOutlined
                      className="text-green-700/70 hover:text-green-800"
                      sx={{ fontSize: 14 }}
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}

      {feedbackModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl">
            <h3 className="text-lg font-semibold mb-2 text-gray-800">
              Report an Issue
            </h3>
            <p className="text-sm text-gray-600 mb-4">
              Please describe the issue you found in this response.
            </p>
            <textarea
              className="w-full h-32 p-3 border border-gray-300 rounded-lg mb-4 focus:ring-2 focus:ring-green-500 focus:border-transparent"
              placeholder="Please describe the issue..."
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            />
            <div className="flex justify-end gap-3">
              <button
                className="px-4 py-2 text-gray-600 hover:text-gray-800 rounded-lg hover:bg-gray-100 transition-colors"
                onClick={() => setFeedbackModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                onClick={handleFeedbackSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatMessages;
