import React, { useState, useEffect, useRef } from "react";
import { Button } from "../@/components/ui/button";
import { LogOut, RefreshCw, X } from "lucide-react";
import { useApi } from "./apiUtils";

interface ConfigPanelProps {
  isOpen: boolean;
  onClose: () => void;
  onLogout: () => void;
  saveConfig: (
    voiceMode: string,
    voiceName: string,
    language: string,
    gender: string
  ) => void;
  getConfig: () => Promise<{
    voice_mode: string;
    voice_name: string;
    language: string;
    gender: string;
  }>;
  refreshKnowledge: () => Promise<{ message: string }>;
}

interface Voice {
  id: string;
  name: string;
  gender: string;
  age: string;
  accent: string;
  description: string;
}

const ConfigPanel: React.FC<ConfigPanelProps> = ({
  isOpen,
  onClose,
  onLogout,
  saveConfig,
  getConfig,
  refreshKnowledge,
}) => {
  const [selectedVoiceMode, setSelectedVoiceMode] = useState<string>("");
  const [selectedVoiceName, setSelectedVoiceName] = useState<string>("");
  const [availableVoices, setAvailableVoices] = useState<Voice[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [availableLanguages, setAvailableLanguages] = useState<string[]>([]);
  const { getAvailableLanguages, getAvailableVoices, isAdmin } = useApi();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [configSaveMessage, setConfigSaveMessage] = useState("");
  const configPanelRef = useRef<HTMLDivElement>(null);
  const [selectedGender, setSelectedGender] = useState<string>("female");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [configResponse, languagesResponse, voicesResponse] =
          await Promise.all([
            getConfig(),
            getAvailableLanguages(),
            getAvailableVoices(),
          ]);

        setSelectedVoiceMode(configResponse.voice_mode);
        setSelectedVoiceName(configResponse.voice_name);
        setSelectedLanguage(configResponse.language);
        setSelectedGender(configResponse.gender);
        setAvailableLanguages(languagesResponse.languages);
        setAvailableVoices(voicesResponse.voices as Voice[]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [getConfig, getAvailableLanguages, getAvailableVoices]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        configPanelRef.current &&
        !configPanelRef.current.contains(event.target as Node) &&
        isOpen
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleVoiceModeChange = (voiceMode: string) => {
    setSelectedVoiceMode(voiceMode);
    saveConfig(voiceMode, selectedVoiceName, selectedLanguage, selectedGender);
    setConfigSaveMessage("Voice mode configuration saved");
    setTimeout(() => setConfigSaveMessage(""), 3000);
  };

  const handleVoiceChange = (voiceName: string) => {
    setSelectedVoiceName(voiceName);
    saveConfig(selectedVoiceMode, voiceName, selectedLanguage, selectedGender);
    setConfigSaveMessage("Voice configuration saved");
    setTimeout(() => setConfigSaveMessage(""), 3000);
  };

  const handleLanguageChange = async (language: string) => {
    setSelectedLanguage(language);

    // Save the new configuration
    await saveConfig(
      selectedVoiceMode,
      selectedVoiceName,
      language,
      selectedGender
    );

    // Fetch new available voices after saving the config
    try {
      const voicesResponse = await getAvailableVoices();
      setAvailableVoices(voicesResponse.voices as Voice[]);

      // Reset the selected voice if it's not available in the new language
      const newVoices = voicesResponse.voices as Voice[];
      if (!newVoices.some((voice) => voice.name === selectedVoiceName)) {
        setSelectedVoiceName(newVoices[0]?.name || "");
        // If we changed the voice, save the config again
        if (newVoices[0]?.name) {
          await saveConfig(
            selectedVoiceMode,
            newVoices[0].name,
            language,
            selectedGender
          );
        }
      }
    } catch (error) {
      console.error("Error fetching voices after language change:", error);
      // Optionally set an error message here
    }

    setConfigSaveMessage("Language configuration saved");
    setTimeout(() => setConfigSaveMessage(""), 3000);
  };

  const handleGenderChange = (gender: string) => {
    setSelectedGender(gender);
    saveConfig(selectedVoiceMode, selectedVoiceName, selectedLanguage, gender);
    setConfigSaveMessage("Gender configuration saved");
    setTimeout(() => setConfigSaveMessage(""), 3000);
  };

  const handleRefreshKnowledge = async () => {
    setIsRefreshing(true);
    setToastMessage("Refreshing knowledge...");
    try {
      const result = await refreshKnowledge();
      setToastMessage(`Knowledge refreshed successfully: ${result.message}`);
    } catch (error) {
      console.error("Error refreshing knowledge:", error);
      setToastMessage("Error refreshing knowledge. Please try again.");
    } finally {
      setIsRefreshing(false);
      setTimeout(() => setToastMessage(""), 3000);
    }
  };

  const renderSection = (title: string, children: React.ReactNode) => (
    <div className="mb-6 bg-green-50/80 rounded-lg shadow-sm p-4">
      <h4 className="text-md font-semibold text-green-800 mb-3">{title}</h4>
      <div className="space-y-3">{children}</div>
    </div>
  );

  const selectClassName =
    "w-full p-2 text-sm rounded-md border border-green-300 focus:outline-none focus:ring-2 focus:ring-green-500 bg-green-50/70 text-green-800 appearance-none cursor-pointer";

  const renderDropdownArrow = () => (
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-green-700">
      <svg
        className="fill-current h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  );

  if (!isOpen) return null;

  return (
    <div
      ref={configPanelRef}
      className="fixed inset-y-0 right-0 w-full sm:w-80 bg-green-100/90 border-l border-green-200 transform transition-transform duration-300 ease-in-out z-40 shadow-lg flex flex-col overflow-y-auto"
    >
      <div className="flex justify-between items-center p-4 border-b border-green-200 bg-green-50/90 shadow-sm">
        <h3 className="text-lg font-semibold text-green-800">Configuration</h3>
        <Button
          onClick={onClose}
          variant="ghost"
          size="sm"
          className="rounded-full hover:bg-green-200 hover:bg-opacity-70"
        >
          <X size={20} />
        </Button>
      </div>
      <div className="p-4 space-y-6 overflow-y-auto">
        {isAdmin ? (
          <>
            {renderSection(
              "Settings",
              <>
                <div className="relative">
                  <select
                    id="voice-mode-select"
                    value={selectedVoiceMode}
                    onChange={(e) => handleVoiceModeChange(e.target.value)}
                    className={selectClassName}
                  >
                    <option value="speech-to-speech">Speech to Speech</option>
                    <option value="speech-to-text">Speech to Text</option>
                    <option value="text-to-text">Text to Text</option>
                  </select>
                  {renderDropdownArrow()}
                </div>
                <div className="relative">
                  <select
                    id="language-select"
                    value={selectedLanguage}
                    onChange={(e) => handleLanguageChange(e.target.value)}
                    className={selectClassName}
                  >
                    {availableLanguages.map((language) => (
                      <option key={language} value={language}>
                        {language}
                      </option>
                    ))}
                  </select>
                  {renderDropdownArrow()}
                </div>
                <div className="relative">
                  <select
                    id="voice-select"
                    value={selectedVoiceName}
                    onChange={(e) => handleVoiceChange(e.target.value)}
                    className={selectClassName}
                  >
                    {availableVoices.map((voice) => (
                      <option key={voice.name} value={voice.name}>
                        {voice.name}
                      </option>
                    ))}
                  </select>
                  {renderDropdownArrow()}
                </div>
                <div className="relative">
                  <select
                    id="gender-select"
                    value={selectedGender}
                    onChange={(e) => handleGenderChange(e.target.value)}
                    className={selectClassName}
                  >
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                  </select>
                  {renderDropdownArrow()}
                </div>
                {selectedVoiceName && (
                  <div className="mt-2 text-xs text-green-600">
                    <p>
                      Description:{" "}
                      {
                        availableVoices.find(
                          (v) => v.name === selectedVoiceName
                        )?.description
                      }
                    </p>
                  </div>
                )}
              </>
            )}

            {configSaveMessage && (
              <div className="mt-2 p-2 bg-green-100 text-green-700 rounded-md text-sm">
                {configSaveMessage}
              </div>
            )}

            {renderSection(
              "Actions",
              <>
                <Button
                  onClick={handleRefreshKnowledge}
                  disabled={isRefreshing}
                  className="w-full bg-blue-100 hover:bg-blue-200 text-blue-700 rounded-full shadow-sm transition-colors duration-200 text-xs py-2"
                >
                  <RefreshCw
                    size={12}
                    className={`mr-1 ${isRefreshing ? "animate-spin" : ""}`}
                  />
                  {isRefreshing ? "Refreshing..." : "Refresh Knowledge"}
                </Button>
                <Button
                  onClick={onLogout}
                  className="w-full bg-red-100 hover:bg-red-200 text-red-600 rounded-full shadow-sm transition-colors duration-200 text-xs py-2"
                >
                  <LogOut size={12} className="mr-1" /> Logout
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            {renderSection(
              "Settings",
              <>
                <div className="relative">
                  <select
                    id="language-select"
                    value={selectedLanguage}
                    onChange={(e) => handleLanguageChange(e.target.value)}
                    className={selectClassName}
                  >
                    {availableLanguages.map((language) => (
                      <option key={language} value={language}>
                        {language}
                      </option>
                    ))}
                  </select>
                  {renderDropdownArrow()}
                </div>
                <div className="relative">
                  <select
                    id="gender-select"
                    value={selectedGender}
                    onChange={(e) => handleGenderChange(e.target.value)}
                    className={selectClassName}
                  >
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                  </select>
                  {renderDropdownArrow()}
                </div>
              </>
            )}

            {configSaveMessage && (
              <div className="mt-2 p-2 bg-green-100 text-green-700 rounded-md text-sm">
                {configSaveMessage}
              </div>
            )}

            <Button
              onClick={onLogout}
              className="w-full bg-red-100 hover:bg-red-200 text-red-600 rounded-full shadow-sm transition-colors duration-200 text-xs py-2"
            >
              <LogOut size={12} className="mr-1" /> Logout
            </Button>
          </>
        )}

        {toastMessage && (
          <div className="mt-4 p-2 bg-blue-100 text-blue-700 rounded-md text-sm">
            {toastMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfigPanel;
